import React, { useCallback, useState } from 'react';
import Dropzone, { ImageFile } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import DropzoneContent from './dropzone-content';
import { UnsplashInput } from '../unsplash';
import { Modal } from '@common/components/modal';
import { ErrorType, Messages } from '../../message';

import { combineClassNames } from '@common/utils/combineClassNames';
import { FileErrorType, FileInputOwnProps, RejectedFileTypes, setFormFileAttachments } from './utils';
import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { FileInputValue } from '@common/types/objects';

export const FileInput = ({
  children, multiple, value, accept, unsplash,
  className, disabled, maxFileSize = 30, processFile = true, onChange, onError,
  ...otherProps
}: FileInputOwnProps) => {
  const { t } = useTranslation();
  const organisationId = useAppSelector(getCurrentOrgId);
  const [rejectedFiles, setRejectedFiles] = useState<ErrorType[]>([]);
  const [isUnsplashModalVisible, setIsUnsplashModalVisible] = useState(false);
  const hasMaxAmountOfFiles = Array.isArray(value) && typeof multiple === 'number' && value.length >= multiple;
  const classNames = combineClassNames('Form__FileInput', className, {
    'Form__Dropzone': !children,
    'Form__Dropzone--has-file': !!(!multiple && !isEmpty(value)),
    'Form__Dropzone--invalid': rejectedFiles.length > 0,
    'Form__FileInput--disabled': disabled || hasMaxAmountOfFiles,
  });

  const onErrorHandler = useCallback((errors: ErrorType[]) => {
    if (onError) onError(errors);
    else setRejectedFiles(errors);
  }, [setRejectedFiles]);

  return (
    <>
      {unsplash && (
        <Modal
          list
          title={t('common:form_input_file_upload_photo_video')}
          show={isUnsplashModalVisible}
          onClose={() => setIsUnsplashModalVisible(false)}
          className="PicturePickerModal"
          content={(
            <UnsplashInput
              processFile={processFile}
              value={value}
              onChange={(...args) => {
                setIsUnsplashModalVisible(false);
                onChange(...args);
              }}
            />
          )}
        />
      )}
      {!onError && rejectedFiles?.length > 0 && <Messages errors={rejectedFiles} />}
      <Dropzone
        accept={accept}
        className={classNames}
        onClick={(e) => void e.preventDefault()}
        onDropRejected={(acceptedOrRejected: ImageFile[]) => {
          acceptedOrRejected.forEach((file) => {
            onErrorHandler([{
              translationKey: RejectedFileTypes[FileErrorType.INVALID],
              props: { name: file.name },
            }]);
          });
        }}
        onDrop={(files) => {
          setRejectedFiles([]);
          setFormFileAttachments({
            files,
            organisationId,
            value,
            onChange,
            onError: onErrorHandler,
            options: { maxFileSize, multiple, processFile },
          });
        }}
        multiple={multiple === 1 ? false : !!multiple}
        disabled={disabled || hasMaxAmountOfFiles}
      >
        {children || (
          <DropzoneContent
            {...otherProps}
            value={(!multiple || multiple === 1) && !isEmpty(value) ? value as FileInputValue : null}
            unsplash={unsplash}
            maxFileSize={maxFileSize}
            setIsUnsplashModalVisible={setIsUnsplashModalVisible}
          />
        )}
      </Dropzone>
    </>
  );
};

