import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Api from '@common/services/api';
import { getApiHeaders, getApiUrl } from '@common/services/api/utils';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import { FileInProgress, FileInputValue } from '@common/types/objects';

import { AlertService } from '@common/services/alert';
import { AsyncList } from '@common/components/list';
import SearchBar from '@common/components/search-bar';
import Placeholder from '@common/components/placeholder';
import { getImageBase64 } from '@common/utils/file';
import { createFileInProgress } from '../file/utils';

type UnsplashInputProps = {
  value?: FileInputValue | FileInputValue[] | null;
  onChange: (value: FileInputValue, index?: number) => void;
  processFile?: boolean;
};

export type UnsplashItem = {
  id: string;
  alt_description?: string;
  width?: string;
  height?: string;
  urls: {
    thumb: string;
    small: string;
    regular: string;
    raw: string;
  };
};

export const UnsplashInput = ({ value, onChange, processFile = true }: UnsplashInputProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | null>('');
  const organisationId = useAppSelector(selected).id;

  const handleSelect = async (item: UnsplashItem) => {
    const res = await fetch(item.urls.regular || item.urls.raw);
    const blob = await res.blob();
    const f = new File([blob], 'image');
    const fileIndex = (value && Array.isArray(value) && value.length) || 0;

    if (processFile) {
      const { data } = await Api.post<{ data: FileInputValue }>(`/v2/organisations/${organisationId}/files/url`, {
        url: item.urls.regular,
        is_document: false,
        file_type: 'image',
      });
      data.preview = await getImageBase64(f);
      onChange(data, fileIndex);
    } else {
      const file: FileInProgress = createFileInProgress(f);
      file.preview = await getImageBase64(f);
      onChange(file, fileIndex);
    }
  };

  const image = ({ item }: { item: UnsplashItem }) => (
    <img
      onClick={() => handleSelect(item)}
      className="Unsplash__Content__Item"
      alt={item.alt_description}
      src={item.urls.thumb}
      srcSet={item.urls.small}
      width={`${item.width}px`}
      height={`${item.height}px`}
    />
  );

  return (
    <>
      <SearchBar onSearch={setSearch} />
      <div className="Unsplash__Container">
        <AsyncList
          containerClassName="Unsplash__Content"
          renderRow={image}
          data={{
            useCursor: undefined,
            filter: {
              query: search,
            },
            onFetch: async (offset, filter) => {
              const limitPerPage = 30;
              const page = +(offset!) / limitPerPage + 1;

              const query = Api.utils.toQuery({
                page,
                per_page: limitPerPage,
                query: filter!.query,
                orientation: 'landscape',
                collections: filter!.query ? undefined : true,
                id: filter!.query ? undefined : 'htcaqHWwbSw',
              });

              const headers = await getApiHeaders();
              const res = await fetch(`${getApiUrl('/unsplash')}?${query}`, { headers });
              if (!res.ok) {
                AlertService.error(t('organisation:track_modal_error'));
                return {
                  data: [] as UnsplashItem[],
                  meta: { pagination: {} },
                };
              }

              const json = await res.json();
              const { results, total } = json.results ? json : { results: json, total: json.length };
              return {
                data: results,
                meta: {
                  pagination: {
                    offset: +(offset!),
                    total_count: total,
                    limit: limitPerPage,
                  },
                },
              };
            },
          }}
          placeholder={(
            <Placeholder
              title={t('common:form_input_file_unsplash_placeholder', { context: search && 'search', search })}
            />
          )}
        />
      </div>
    </>
  );
};
