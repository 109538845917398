import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { Button } from '@common/components/button';
import { FileInProgress, FileInputValue } from '@common/types/objects';

type DropzoneContentProps = {
  value?: FileInputValue | null;
  placeholder?: string;
  acceptedExtLabel?: string;
  maxFileSize: number;
  unsplash?: boolean;
  setIsUnsplashModalVisible: (isUnsplashModalVisible: boolean) => void;
};

const DropzoneContent = ({
  value,
  placeholder,
  acceptedExtLabel,
  maxFileSize,
  unsplash,
  setIsUnsplashModalVisible,
}: DropzoneContentProps) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState<number | null>(null);

  useEffect(() => {
    const file = value as FileInProgress;
    if (file?.progress) file.progress((newValue) => setProgress(newValue));
    return () => file?.progress?.(undefined);
  }, [value]);

  return (
    <div className="Form__FileInput__DefaultTrigger">
      {value ? (
        <div className="Form__FileInput_Uploading">
          <div>
            <Icon type="arrow_circle_down__filled" />
            {value.file_name}
          </div>
          {value.processing && (
          <small>
            {(progress || 0) < 100
              ? <Trans i18nKey="common:form_input_file_uploading" values={{ percentage: Math.round(progress || 0) }} />
              : <Trans i18nKey="common:form_input_file_processing" />}
          </small>
          )}
        </div>
      ) : (
        <>
          <div className="Form__FileInput__DefaultHeader">
            <Icon type="arrow_circle_down__filled" />
            {placeholder || (
              <b><Trans i18nKey="common:form_input_file_drag_or_click" components={[<u />]} /></b>
            )}
          </div>

          <small>{acceptedExtLabel}</small>
          <small>{`(${t('common:form_input_file_max_file_size', { maxFileSize })})`}</small>

          {unsplash && (
            <Button
              className="Form__FileInput__UnsplashModalButton stopPropagation"
              onClick={(e) => {
                e.stopPropagation();
                setIsUnsplashModalVisible(true);
              }}
            >
              {`${t('common:form_input_file_unsplash_library')}...`}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default DropzoneContent;
