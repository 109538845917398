import React from 'react';
import { omit } from 'lodash';
import {
  Field,
  WrappedFieldProps,
  BaseFieldProps,
  FieldArray,
  WrappedFieldArrayProps,
} from 'redux-form';

import { FileInput } from './file-input';
import {
  FileInputProps,
  FileInputHookFormProps,
  getFileInputHookFormProps,
  getMultipleFileInputFormProps,
} from './utils';

/**
 * Warning: This component is only for unlimited multiple files.
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const FileInputHookForm = (props: FileInputHookFormProps) => (
  <FileInput {...getFileInputHookFormProps(props)} />
);

const ReduxFormField = (props: WrappedFieldProps & FileInputProps) => {
  return (
    <FileInput
      {...props.input}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

const ReduxFormFieldMultiple = (props: WrappedFieldArrayProps & FileInputProps) => {
  return (
    <FileInput {...getMultipleFileInputFormProps(props)} />
  );
};

export default (props: Omit<BaseFieldProps<FileInputProps>, 'component'> & FileInputProps) => (
  props.multiple ?
    <FieldArray {...props} component={ReduxFormFieldMultiple} /> :
    <Field {...props} component={ReduxFormField} />
);
